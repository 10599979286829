














































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Icon from "../../globalUsage/Icon.vue";
import { namespace } from "vuex-class";
import moment from "moment";
const Auth = namespace("Auth");
const InvestmentOrders = namespace("InvestmentOrders");

@Component({
  components: { Icon },
})
export default class CoinDetail extends Vue {
  dialog: false = false;
  url: string = "http://replica.highpolar.in";
  @Prop() assetName!: any;
  @Prop() quantity!: any;
  @Prop() totalValues!: any;
  @Prop() lastActivity!: any;
  @Prop() Icon!: any;
  @Prop() id!: any;
  details: any = [];

  getDate(date: any) {
    return moment(date).format("LL hh:mm");
  }

  @Auth.Getter
  private userGetter!: any;

  @InvestmentOrders.Action
  private getInvestmentOrderPastDetails!: (data: any) => Promise<any>;

  getDetails() {
    this.getInvestmentOrderPastDetails({
      id: this.id,
      access: this.userGetter.access,
    }).then((response) => {
      this.details = this.details.concat(response.data);
      console.log("11", this.details);
    });
  }
}
